import React from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../assets/images/Logo/logo.webp';


const Header = () => {
    const location = useLocation();
    const { pathname } = location;
    
    const splitLocation = pathname.split("/");

    return (
        <>
        <Navbar expand="xl" className="bg-white py-1" id="website-nav" style={{borderBottom:"5px solid #f51a44"}}>
            <Container fluid className='px-md-5'>
                <Navbar.Brand><Link
                className="flex-wrap navbar-brand d-flex align-items-baseline"
                to="/"
                >
                <img
                    src={logo}
                    alt="Logo"
                    className="img-fluid logo-img"
                />
                </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
                <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-xl`}
                aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                placement="start"
                className=""
                >
                <Offcanvas.Header closeButton className='pb-0'>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                        <Link
                        className="flex-wrap navbar-brand d-flex align-items-baseline"
                        to="/"
                        >
                        <img
                            src={logo}
                            alt="Logo"
                            className="py-2 img-fluid"
                            style={{height: "60px"}}
                        />

                        </Link>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                {/* <hr /> */}
                <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3 text-raleway website-np align-items-nav">
                    <Link to="/" className={splitLocation[1] === "what-is-ccm" ? "active py-2 fs-ss-16 nav-link text-raleway" : "py-2 fs-ss-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Home</Link>
                    {/* <Link to="/what-is-ccm" className={splitLocation[1] === "what-is-ccm" ? "active py-2 fs-ss-16 nav-link text-raleway" : "py-2 fs-ss-16 nav-link text-raleway"} style={{fontWeight:"500"}}>What is CCM</Link>
                        <Link to="/ccm-essentials" className={splitLocation[1] === "ccm-essentials" ? "active py-2 fs-ss-16 nav-link text-raleway" : "py-2 fs-ss-16 nav-link text-raleway"} style={{fontWeight:"500"}}>CCM Essentials</Link> */}
                        <Link to="/features" className={splitLocation[1] === "features" ? "active py-2 fs-ss-16 nav-link text-raleway" : "py-2 fs-ss-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Features</Link>
                        <Link to="/faqs" className={splitLocation[1] === "faqs" ? "active py-2 fs-ss-16 nav-link text-raleway" : "py-2 fs-ss-16 nav-link text-raleway"} style={{fontWeight:"500"}}>FAQs</Link> 
                        <Link to="/contact-us" className={splitLocation[1] === "contact-us" ? "active py-2 fs-ss-16 nav-link text-raleway" : "py-2 fs-ss-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Contact</Link>

                        <Link to="/book-a-demo" className="ms-lg-3 py-2 fs-ss-17 nav-link text-poppins mt-xl-0 mt-3 btn-ss-secondary btn text-white px-3" style={{fontWeight:"300"}}>
                            Book a Demo
                        </Link>

                    </Nav>
                    
                </Offcanvas.Body>
                </Navbar.Offcanvas>
                
            </Container>
        </Navbar>
        </>
    )
}

export default Header