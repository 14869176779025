import React from 'react'
import {Link} from 'react-router-dom'
import {PlayFill, TelephoneFill, EnvelopeFill, ChatLeftTextFill } from 'react-bootstrap-icons';
import logo from '../../assets/images/Logo/logo.webp'

const Footer = () => {
    return (
        <>
            <footer id="website-responsive">
                <div className="footer-bg" >
                    <div className='container-ss py-4'>
                        <div className='row pt-4 gx-md-5'>
                            <div className='col-lg-5 col-md-12 mb-3'>
                                <img src={logo} className='img-fluid footer-logo' alt='logo' />
                                <div className='spacer-ss-15'/>
                                <div className='fs-ss-17 fw-400'>
                                Our cutting-edge texting solutions enable healthcare practices to enhance patient satisfaction and profitability. We're committed to delivering the tools you need to thrive in the digital era. Join us in shaping the future of healthcare communication. 
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-4'>
                                <div className='row ps-lg-5'>
                                    <div className="col-lg-6">
                                        <div className='text-open-sauce-one fs-ss-20 text-nowrap' style={{fontWeight:"600"}}>Quick Links</div>
                                        {/* <hr className='border-ss-secondary-footer my-2'/> */}
                                        <ul className='list-unstyled mt-3'>
                                            <li><Link to='/' className='text-decoration-none text-dark lh-lg fs-ss-17'><PlayFill className='me-1'/>Home</Link></li>
                                            <li><Link to='/features' className='text-decoration-none text-dark lh-lg fs-ss-17'><PlayFill className='me-1'/>Features</Link></li>
                                            <li><Link to='/contact-us' className='text-decoration-none text-dark lh-lg fs-ss-17'><PlayFill className='me-1'/>Contact Us</Link></li>
                                            <li><Link to='/privacy-policy' className='text-decoration-none text-dark lh-lg fs-ss-17'> <PlayFill className='me-1'/>Privacy Policy</Link></li>
                                            <li><Link to='/terms-of-use' className='text-decoration-none text-dark lh-lg fs-ss-17'> <PlayFill className='me-1'/>Terms of Use</Link></li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='text-open-sauce-one fs-ss-20' style={{fontWeight:"600"}}>Contact</div>
                                            {/* <hr className='border-ss-secondary-footer my-2'/> */}
                                            <ul className='list-unstyled mt-3'>
                                                <li><span className='text-decoration-none text-dark lh-lg '><ChatLeftTextFill className='me-2' /> Text us at: +1 (256) 929-5220</span></li>
                                                <li><a href='tel:+12569295220' className='text-decoration-none text-dark lh-lg '><TelephoneFill className='me-2' /> Call us at:  +1 (256) 929-5220</a></li>
                                                <li><a href='mailto:support@ezhorizon.com' className='text-decoration-none text-dark lh-lg '><EnvelopeFill className='me-2' /> support@ezhorizon.com </a></li>
                                            </ul>
                                            <Link to="/book-a-demo" className="py-2 fs-ss-17 nav-link text-poppins mt-4 btn-ss-secondary btn text-white mb-md-0 mb-3" style={{fontWeight:"300"}}>
                                                Book a Demo
                                            </Link>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                <div className='py-2 bg-ss-secondary'>
                    <div className='container-zk'>
                        <div className='text-center'>
                            <p className='p-0 m-0 text-white fs-ss-14 px-md-0 px-2'>Copyright © 2023 Horizon Medical Texting | Powered by <a href='https://simplesolutionz.org/' target="_blank" rel="nofollow" className='text-decoration-none text-white'>Simple Solutionz</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
