import React from 'react'
import {Link} from 'react-router-dom'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import banner from '../assets/images/Home/banner.png'
import banner_mb from '../assets/images/Home/banner_mb.png'
import Laptop from '../assets/images/Home/Laptop.png'
import DoctorImgWithLaptop from '../assets/images/Home/DoctorImgWithLaptop.png'
import DoctorImg from '../assets/images/Home/DoctorImg.png'
// import MiddleBanner from '../assets/images/Home/MiddleBanner.png'
import FrontMiddleBanner from '../assets/images/Home/FrontBenefitsToPractice.png'
import Icon1 from '../assets/images/Home/Icon1.png'
import Icon2 from '../assets/images/Home/Icon2.png'
import Icon3 from '../assets/images/Home/Icon3.png'
import Icon4 from '../assets/images/Home/Icon4.png'
import Mobile from '../assets/images/Home/Mobile.png'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet'


const Home2 = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnHover: true,
        arrows : false

      };
      window.scrollTo(0, 0);
    return (
        <React.Fragment>
            <Helmet>
                <title>Horizon Medical Texting</title>
            </Helmet>
            <Header />
                <div className="slider-wrapper text-lg-start text-center">
                    <Slider {...settings}>
                        <div className="slick-slide" key="1">
                            <img className="slick-slide-image d-none d-sm-none d-md-block" alt='Home Banner' src={banner} style={{width:"100%"}}/>
                            <img className="slick-slide-image d-md-none d-sm-block" alt='Home Banner' src={banner_mb} style={{width:"100%"}}/>
                            <div className='slick-slide-title banner-title'>
                                <div className='mb-2'>
                            <div className='mb-2'>
                            Great Patient <br className='d-none d-md-block'/> Relationships Are Built <br className='d-none d-md-block'/> on Conversation So is <br/> Horizon Texting
                            </div>
                                <div className='banner-para fw-400 fw-bold'>
                                    Stop texting to your patients, have conversations with them.
                                </div>
                                <div className='banner-para col-11 col-md-5 mx-auto mx-sm-0 px-1 fw-400 mt-lg-4 mt-1 fw-bold bg-ss-secondary' style={{fontSize:"16px"}}>
                            Do not lose your patients to other practices or urgent care centers due to busy phone lines and long time on hold.
                            </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <section id='website-responsive' className="container-fluid pt-4 pt-lg-5">
                    <div className="row justify-content-center">
                    <div className="col-11 col-md-10 text-center fs-ss-18 fw-500 pt-4 lh-36">
                    {/* Do not lose your patients to other practices or urgent care centers.
                    If your phone lines are busy or there is a long time on hold, patients go somewhere else!!! 
                    Horizon Texting is a two-way, secure, and HIPAA-compliant texting software. With Horizon Texting, you can improve patient communication, reduce no-show rates, and streamline appointment scheduling, medication refills, and referral processes. 
                    Horizon Texting enhances overall patient satisfaction and helps healthcare providers save time and resources through automatic routine communication tasks.  */}
                    
                    Horizon Medical Texting is a modern patient communication software designed for thriving healthcare practices. Horizon Medical Texting is a secure, two-way, HIPAA complaint texting software. <span className='text-ss-secondary'> A practice can lose $100 per patient or few hundred dollars per day </span> due to busy phone lines and missed appointments. Our software can help! 
                    
                    <div className="text-ss-secondary fw-bold pt-3"> Try calling your own office to see how long it can take to make an appointment! With Horizon Texting, it takes less than a minute.  </div>
                    </div>
                    </div>
                </section>
                <div id='website-responsive' className='container-fluid py-4 py-md-5'>
                <div className='row justify-content-center gy-4 align-items-center'>
                <div className='col-md-4 text-md-end text-center'>
                        <img className='w-50' src={Mobile} alt="Laptop" />
                </div>
                    <div className='col-md-8 rounded-start rounded-0 py-md-5 pt-4'>
                        <div className='container-ss'>
                            <h3 className='text-uppercase text-ss-secondary fs-ss-35 fw-600 '>
                            DON’T LOSE YOUR PATIENTS     
                    <div className='text-dark fs-ss-30 text-capitalize'>
                    to Your Competitors  
                    </div>
                        </h3>
                        <div className='fs-ss-17 fw-400'>
                        Your front desk and poor customer service are the main reasons patients leave a practice. Patients may still like you, but they go to other practices because your staff does not take care of their needs. Patients go to other providers if they cannot get through to your office, phone lines are busy, or spend long periods on hold. Patients leave a practice if messages are lost, or they do not receive responses in a timely manner. Quite often, patients have to call the office 2-3 times to receive their lab results or medication refills, and office staff fail to take care of these needs, as they may be busy on Facebook or YouTube!! With Horizon Texting software, messages are not lost, and the system holds staff members accountable for the completion of assigned tasks. 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id='website-responsive' className=''>
                    <section className='col-11 col-lg-10 mx-auto py-md-5 py-2 text-start text-lg-center'>
                    <div className=''>
                    <h3 className='text-uppercase text-ss-secondary fs-ss-35 fw-600 '>
                    OPTIMIZE PATIENT
                    <div className='text-dark fs-ss-30 text-capitalize'>
                    Connections And Operations
                    </div>
                        </h3>
            <div className='pt-3 text-poppins fs-ss-17'>
            With Horizon Texting, you can improve patient communication, reduce missed appointments and no-show rates, and streamline appointment scheduling, medication refills, and referral processes. Horizon Texting enhances overall patient satisfaction and helps healthcare providers save time and resources by assisting with automatic routine communication tasks.
            </div>
        </div>
                    </section>
                    </div>
                <div id='website-responsive' className='container-fluid py-4 py-md-5'>
                <div className='row justify-content-center gy-4 align-items-center'>
                    <div className='col-md-7 rounded-start rounded-0 py-md-5 pt-4'>
                        <div className='container-ss'>
                        <h3 className='text-uppercase text-ss-secondary fs-ss-35 fw-600 '>
                        Benefit to Patients 
                        </h3>
                        <div className='spacer-ss-15'/>
                        <div className='fs-ss-17 fw-400'>
                           <div className='d-flex align-items-start '> 
                            <div className='pe-3 '>
                            <img className='' src={Icon1} alt="" style={{width:"30px"}} /> 
                            </div>
                            <div>
                            No time spent on hold.
                            </div>
                            </div>
                           <div className='py-3  align-items-start d-flex'> 
                           <div className='pe-3 '>
                           <img className='' src={Icon2} alt="" style={{width:"30px"}} /> 
                           </div> 
                           <div>
                           Easily make appointments, request medication refills, or receive lab, x-ray, and test results.
                           </div>
                           </div>
                           <div className='d-flex align-items-start '> 
                            <div className='pe-3 '>
                            <img className='' src={Icon3} alt="" style={{width:"30px"}} /> 
                            </div>
                            <div>
                            Accessible conversation history that prevents lost messages
                            </div>
                            </div>
                            <div className='py-3 d-flex align-items-start '> 
                            <div className='pe-3 '>
                            <img className='' src={Icon4} alt="" style={{width:"30px"}} /> 
                            </div>
                            <div>
                            Patients feel empowered knowing they have instantaneous and direct access to their physicians and nurses. Having a number to text allows patients to feel secure in their ability to access assistance with any need they may have.                            
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-5 text-md-start text-center'>
                        <img className='w-100' src={Laptop} alt="Laptop" />
                </div>
            </div>
        </div>
        <div id='website-responsive' className="container-fluid bg-benefits-to-practice text-center">
            <div className='pt-lg-5 pb-lg-4 '>
            <h3 className='text-uppercase text-ss-secondary fs-ss-35 fw-600 '>
                Benefit to Practice 
            </h3>
            </div>
            <img src={FrontMiddleBanner} className='w-100' alt="" />
        </div>
        {/* <img src={MiddleBanner} className='w-100'>
          </img> */}
          {/* <div className="py-4">
            <img src={MiddleBanner} alt="gif here" className='w-100 d-block d-md-none' />
          </div> */}
          <div id='website-responsive' className='py-4 py-lg-5'>
                    <section className='ps-3 ps-lg-0 py-md-5 py-2 text-start text-lg-center'>
                    <div className='container-ss'>
                    <h3 className='text-uppercase text-ss-secondary fs-ss-35 fw-600 '>
                    FIND OUT WHO IS WORKING AND WHO IS NOT! 
                    <div className='text-dark fs-ss-30 text-capitalize'>
                    Enforce Accountability In Your Office 
                    </div>
                        </h3>
            <div className='pt-3 text-poppins fs-ss-17'>
            Yes, our software can tell you which employees are really doing their job. The software allows you to assign text messages to different members of your staff. You can assign text messages to nurses, billing personnel, referral coordinators, etc. You can monitor employee performance by tracking the completion of tasks/messages assigned to them. For example: if a patient has requested lab results or medication refills 3 times and the nurse did not take care of it—then she is not doing her job. The software informs you how many patients are still waiting to hear back from your office by the end of the day and which staff members have not responded to these messages.
            </div>
        </div>
                    </section>
                    </div>
          <div id='website-responsive' className='container-fluid py-4 py-md-5'>
                <div className='row justify-content-evenly gy-4 align-items-start'>
                <div className='col-md-4 text-md-end d-none d-md-block text-center'>
                        <img className='w-75' src={DoctorImgWithLaptop} alt="Doctor Img With Laptop" />
                </div>
                    <div className='col-md-8 rounded-start rounded-0 '>
                        <div className='container-ss'>
                        <h3 className='text-uppercase text-ss-secondary fs-ss-35 fw-600 '>
                        Enhance Patient Satisfaction 
                                    <div className='text-dark fs-ss-30 text-capitalize'>
                                    Add Value to Your Practice 
                                    </div>
                        </h3>
                        <div className='spacer-ss-15'/>
                        <div className='fs-ss-17 fw-400'>
                        All medical practices have the same complaint—too many phone calls. The phone is ringing all the time. All patients have the same complaint: “The phone line is always busy,” “No one picks up the phone when I call,” or “I was on hold forever.” Horizon Texting provides instantaneous access to your patients and to your office staff without being placed on hold. Streamline appointment scheduling, medication refills, and referral process at your office. 
                        </div>
                        <div className='pt-3 pb-3 pb-lg-0 text-center text-lg-start'>
                        <Link to="/book-a-demo" className="py-2 fs-ss-17 text-poppins mt-xl-0 mt-3 btn-transparent btn px-4 fw-500">
                        Book a Demo
                    </Link>
                    </div>
                    </div>
                </div>
                <div className='col-8 text-md-start d-block d-md-none text-center'>
                        <img className='w-100' src={DoctorImgWithLaptop} alt="Doctor Img With Laptop" />
                </div>
            </div>
        </div>
        <div className="pt-4 pt-lg-5"/>
        <div id='website-responsive'>
                    <section className='pb-md-4 pb-2 text-center'>
                    <div className='container-ss'>
                    <h3 className='text-uppercase text-ss-secondary fs-ss-35 fw-600 '>
                    Provide
                    <div className='text-dark fs-ss-30 text-capitalize'>
                        what patients want
                    </div>
                        </h3>
            <div className='pt-3 text-poppins fs-ss-17'>
            Patients prefer text messaging, rather than annoying phone calls. They do not want to find phone lines busy and not able to communicate with someone. They do not like being put on hold. They do not like missed or lost messages. They expect and demand timely performance from your practice, or they go somewhere else!!! With Horizon Texting, you eliminate all issues and provide a better patient experience and satisfaction.
            </div>
        </div>
                    </section>
                    </div>

        <div id='website-responsive' className='container-fluid py-4 py-md-5'>
                <div className='row justify-content-evenly gy-4 align-items-center'>

                    <div className='col-md-7 rounded-start rounded-0 py-md-5 pt-4'>
                    <div className='container-ss'>
                        <h3 className='text-uppercase text-ss-secondary fs-ss-35 fw-600 '>
                        Less expense and more
                            <div className='text-dark fs-ss-30 text-capitalize'>
                            revenue for your practice
                            </div>
                        </h3>
                        <div className='spacer-ss-15'/>
                        <div className='fs-ss-17 fw-400'>
                        Our software works like a digital receptionist. With the use of Horizon Texting Software, you need fewer office staff to attend patient calls and spend less time during refills, referrals, etc. One less employee at the clinic means about $35000 of savings.
                        </div>
                    </div>
                </div>
                <div className='col-8 col-md-3 text-md-start text-center'>
                        <img className='w-100' src={DoctorImg} alt="Doctor Img With Laptop" />
                </div>
            </div>
        </div>
        <div id='website-responsive' className='container-fluid no-row-space pb-lg-4' style={{backgroundColor:"#f8f8f8"}}>
            <div className='row justify-content-center'>
                <div className='col-12 col-md-11 text-center py-md-3 py-4'>
                    <div className='py-md-5 py-2'>
                    <div className='col-12 mx-auto'>
                      <div className='fs-ss-40 fw-600 text-uppercase' style={{color:"#212529"}}>
                      More Revenue
                      </div>
                      <div className='pt-4 text-ss-secondary fw-700 fs-ss-18'>A practice loses $100 per patient or a few $100 in a day if your phone lines are busy and patients cannot get an appointment. They go to other providers or urgent care clinics. A single long-term patient brings a revenue of about $50,000 to practice over the long run.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <Footer />
        </React.Fragment>
    )
}

export default Home2
