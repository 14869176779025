import React,{Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './pages/Loading';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import TermsOfUse from './pages/TermsOfUse';
import Home2 from './pages/Home2';

const Home = React.lazy(() => import('./pages/Home'));
const Features = React.lazy(() => import('./pages/Features'));
const Contact = React.lazy(() => import('./pages/Contact'));
const FAQ = React.lazy(() => import('./pages/FAQ'));
const BookDemo = React.lazy(() => import('./pages/BookDemo'))

const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const TermsConditions = React.lazy(() => import('./pages/TermsConditions'))


const Error = React.lazy(() => import('./pages/Error'))
function App() {
    return (
        <React.Fragment>
            <Suspense fallback={<Loading/>}>
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home2" element={<Home2 />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/faqs" element={<FAQ />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/book-a-demo" element={<BookDemo />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="*" element={<Error />} />
                </Routes>
            </Suspense>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
            />
        </React.Fragment>
    );
}

export default App;
